
.icon {
  display: block;
  fill: currentColor;
  height: 30px;
  margin: 0 auto;
  width: 30px;
}

.stat:first-child {
  padding-left: 0;
}

.stat:last-child {
  padding-right: 0;
}

@media (min-width: 768px) {
  .icon {
    height: 70px;
    width: 70px;
  }
}
